import * as React from "react"
import styled from '@emotion/styled'
import Layout from '../components/Layout/Layout';
import { PageTitleHeader } from '../globalStyles';

import FAQs from "../content/faqs.json";


const blissfulColour = "#ee9fa0;"

const Container = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 0 20px;
`

const Question = styled.h2`
  color: ${blissfulColour};
`

const QuestionAndAnswer = styled.div`
  width: 100%;
  margin-right: 15px;
  @media screen and (max-width: 960px) {
    margin: 5px 0px;
    flex-basis: 100%;
  }
`

const QuestionContainer = styled.div`
  width: 100%;
  margin: 40px 0;
`

const Faqs = () => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  return (
    <Layout
      title="Frequently Asked Questions"
      description="Have a question? We have answers to our most frequently asked questions. Check to see if we've answered your question."
      pathname={pathname}
    >   
      <Container>
        <PageTitleHeader> Frequently Asked Questions </PageTitleHeader>

        { FAQs.map((qna) => {
          return <>
            <QuestionContainer key="question">
               <QuestionAndAnswer>
                <Question> 
                  {qna.question}
                </Question>
                <div dangerouslySetInnerHTML={{__html: qna.answer}}></div>
              </QuestionAndAnswer>
            </QuestionContainer>
          </>
        }) }

        </Container>
    
    </Layout>
  )
}

export default Faqs

